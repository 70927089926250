<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="vorgangData"
      :fields="vorgangFields"
      :busy="isVorgangLoading"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(zeitraum)="row">
        <div style="width: 150px">
          {{ formatDateWithoutHours(row.item.reisedatum) }} -
          {{ formatDateWithoutHours(row.item.enddatum) }}
        </div>
      </template>
      <template v-slot:cell(kundenname)="row">
        <div style="width: 170px">
          {{ kundenName(row.item) }}
        </div>
      </template>
      <template v-slot:cell(blankState)="row">
        <VorgangStateWidget v-if="row.item?.blankState" :state="row.item?.blankState" />
      </template>
      <template v-slot:cell(released)="row">
        <i :class="row.item.isReleased ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
      </template>
      <template v-slot:cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-vorgang' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-vorgang' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';

export default {
  props: { vorgangData: { type: Array, required: true }, openAufgabenId: { type: Number, default: null } },
  components: { VorgangStateWidget, AufgabenWidget },
  data() {
    return {
      isVorgangLoading: false,
      vorgangDataExpanded: [],
      vorgangFields: [
        {
          tdClass: 'align-middle',
          key: 'id',
          label: 'BNR',
        },
        {
          tdClass: 'align-middle',
          key: 'pnr',
          label: 'PNR',
          formatter: value => value?.join(','),
        },
        {
          tdClass: 'align-middle',
          key: 'kundenname',
        },
        {
          tdClass: 'align-middle',
          key: 'zimmer',
          formatter: value => value.join(', '),
        },
        {
          tdClass: 'align-middle',
          key: 'npx',
          label: 'Pax',
        },
        {
          tdClass: 'align-middle',
          key: 'blankState',
          label: 'Buchungsstatus',
        },
        {
          tdClass: 'align-middle',
          key: 'released',
        },
        { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
      ],
    };
  },
  methods: {
    kundenName(item) {
      if (item?.kunde?.master) {
        return item?.kunde?.master.vorname + ' ' + item?.kunde?.master.nachname;
      } else return '';
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
    rowClicked(item) {
      //   window.open('/#/reisen/' + item.reisekuerzel + '/edit', '_blank');
    },
  },
};
</script>
